import Router from 'next/router';
import { getSearchUrlWithFilters } from '@bridebook/toolbox';
import { authenticatedPOST } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { CountryCodes, Market } from '@bridebook/toolbox/src/gazetteer';
import { TGetSearchUrlWithFiltersParams } from '@bridebook/toolbox/src/getSearchUrlWithFilters';
import { countriesExcludedTypes } from '@bridebook/toolbox/src/supplier/constants';
import type { Slug } from '@bridebook/toolbox/src/types';
import {
  TSearchSupplierTypesRequestBody,
  TSearchSupplierTypesResponse,
} from 'pages/api/search/supplier-types';
import { ApiEndpoint } from 'lib/api/api-endpoint';
import { getPopularVenueTypesTexts } from 'lib/search-landing/utils';
import { setPopularVenueTypes, toggleMapView } from 'lib/search/actions';
import { getIsMapView } from 'lib/search/selectors';
import {
  extractAreaParams,
  getDefaultSearchArea,
  isGlobalLocationsPage,
  isSearchResultsPage,
} from 'lib/search/utils';
import { IDeps } from 'lib/types';
import { PublicUrls, UrlHelper } from 'lib/url-helper';
import { noopAction } from 'lib/utils';
import { assertState } from 'lib/utils/assertState';
import { POPULAR_VENUE_TYPES } from './constants';

export const navigateToSearchLanding = () => () => {
  const asyncAction = async () => {
    await Router.push(PublicUrls.searchLanding);
  };
  asyncAction();

  return noopAction();
};

/**
 * The below function should only be called for logged-in users
 * as the global locations page is not available for logged-out
 * */
const navigateToGlobalLocations = () => () => {
  const asyncAction = async () => {
    await Router.push(UrlHelper.globalLocations);
  };
  asyncAction();

  return noopAction();
};

export const mobileNavigateToSearchLandingOrRecentSearch =
  () =>
  ({ getState, dispatch }: IDeps) => {
    const asyncAction = async () => {
      const state = getState();

      const supplierNavigateTarget = state.search.currentSessionSupplierNavigateTarget;
      const isMapView = getIsMapView(state);

      // if current page is search results and map view is open
      // simply close map and stay in list view
      if (isSearchResultsPage(Router.asPath) && isMapView) {
        return dispatch(toggleMapView(false));
      }

      if (
        supplierNavigateTarget?.target === 'globalLocations' &&
        !isGlobalLocationsPage(Router.asPath)
      ) {
        dispatch(navigateToGlobalLocations());
      } else if (
        supplierNavigateTarget?.target === 'recentSearch' &&
        !isSearchResultsPage(Router.asPath)
      ) {
        dispatch(navigateToSearchResults(supplierNavigateTarget.recentSearchData));
      } else {
        dispatch(navigateToSearchLanding());
      }
    };
    asyncAction();

    return noopAction();
  };

export const navigateToSearchResults =
  (
    params: Partial<Omit<TGetSearchUrlWithFiltersParams, 'slug'>> & {
      slug?: Slug;
      country?: CountryCodes;
    },
  ) =>
  ({ getState }: IDeps) => {
    const asyncAction = async () => {
      const state = getState();
      assertState(state.search.searchLocation, 'initialized', 'search');
      let as = '';

      if (params.area) {
        as = getSearchUrlWithFilters({
          slug: params.slug ?? 'venue',
          area: params.area,
          filters: params.filters ?? {},
          as: true,
          ...params,
          areaContext: params.areaContext
            ? params.areaContext
            : params.country
            ? [params.country]
            : [],
        });
      } else {
        const { location, country } = extractAreaParams(state.search.searchLocation.selected.area);
        as = getSearchUrlWithFilters({
          slug: params.slug ?? 'venue',
          area: location,
          filters: {},
          as: true,
          ...params,
          areaContext: country
            ? [country]
            : state.search.searchLocation.selected.countryCode
            ? [state.search.searchLocation.selected.countryCode]
            : [],
        });
      }

      await Router.push(as, undefined, { shallow: false }).then(() => window.scrollTo(0, 0));
    };
    asyncAction();

    return noopAction();
  };

export const fetchPopularVenueTypes =
  (market: Market) =>
  ({ getState, dispatch }: IDeps) => {
    const asyncAction = async () => {
      const state = getState();
      const { searchLocation, popularVenueTypes } = state.search;
      assertState(searchLocation, 'initialized', 'search');
      const searchLocationArea = searchLocation.selected.area;

      // If the location is the same, no need to request again
      if (searchLocationArea === popularVenueTypes?.location) return;

      dispatch(
        setPopularVenueTypes({
          list: [],
          isPending: true,
          location: searchLocationArea,
        }),
      );

      const area =
        searchLocation.selected.area || getDefaultSearchArea({ countryCode: market.country });

      const body: Partial<TSearchSupplierTypesRequestBody> = {
        area,
        country: getDefaultSearchArea({ countryCode: market.country }),
        countryCode: market.country,
        locationInfo: true,
        searchParams: ['wedding-venues', area],
        type: 'venue',
      };

      const result = (await authenticatedPOST(ApiEndpoint.search.supplierTypes, {
        body,
      }).catch(() => null)) as TSearchSupplierTypesResponse | null;

      // If there was another search triggered while waiting for the response
      // of the supplierTypes, don't save the data of the initial response
      const anotherSearchLocation = getState().search.searchLocation;
      assertState(anotherSearchLocation, 'initialized', 'search');
      if (!result || searchLocationArea !== anotherSearchLocation.selected.area) return;

      const texts = getPopularVenueTypesTexts();

      const venueTypes = Object.keys(result)
        .reduce<string[]>((acc, venueType) => {
          acc.push(venueType);
          return acc;
        }, [])
        .filter(
          (venueType) =>
            result[venueType] > 0 &&
            !!texts[venueType] &&
            countriesExcludedTypes[market.country]?.has(venueType) !== true &&
            POPULAR_VENUE_TYPES.venueTypesImgs.has(venueType),
        );

      const isValidList = venueTypes.length >= POPULAR_VENUE_TYPES.minCards;

      if (isValidList) {
        venueTypes.sort((a, b) => result[b] - result[a]);
      }

      dispatch(
        setPopularVenueTypes({
          list: isValidList ? venueTypes.slice(0, POPULAR_VENUE_TYPES.maxCards) : [],
          isPending: false,
          location: searchLocationArea,
        }),
      );
    };

    asyncAction();

    return noopAction();
  };
