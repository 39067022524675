import {
  fetchBadgesSuccess,
  participatedInABTestAnalytics,
  setABTestVariant,
  setABTestsLoaded,
  settingsToggleChangeEmailForm,
  settingsToggleLinkEmailForm,
  showMobileNav,
  toggleFullscreenImageModal,
  toggleLostLeadModal,
  toggleNavMenuDrawer,
  togglePushNotificationOptIn,
  togglePushNotificationOptInAnalytics,
  toggleSpecialOfferPopup,
  usedQuickNavigationOnHomepage,
} from './actions';

export enum UIActionTypes {
  COPIED_URL = '[UI] Copied url',
  DEBOUNCED_GO_BACK_HOME = '[UI] Debounced go back home',
  RESET_COPIED_URL = '[UI] Reset copied url',
  TOGGLE_FULLSCREEN_IMAGE_MODAL = '[UI] Toggle fullscreen image modal',
  TOGGLE_LINK_EMAIL_FORM = '[UI] Show basic auth form titles',
  TOGGLE_NAV_MENU_DRAWER = '[UI] Toggle navigation menu drawer',
  TOGGLE_PUSH_NOTIFICATION_OPT_IN = '[UI] Toggle push notification opt in',
  TOGGLE_PUSH_NOTIFICATION_OPT_IN_ANALYTICS = '[Analytics] [UI] Toggle push notification opt in',
  TOGGLE_SEARCH_BAR = '[UI] Toggle search bar',
  TOGGLE_SETTINGS_CHANGE_EMAIL_FORM = '[UI] Toggle settings change email form',
  TOGGLE_SPECIAL_OFFER_POPUP = '[UI] Toggle special offer popup',
  TRIGGERED_SPECIAL_OFFER_POP_UP_ANALYTICS = '[Analytics] [UI] Triggered special offer popop',
  COUPLE_CLICKED_INBOX_MENU_ANALYTICS = '[Analytics] [UI] Couple clicked Messages item in burger menu',
  SET_ABTEST_VARIANT = '[UI] Set AB Test variant',
  SET_ABTESTS_LOADED = '[UI] Set AB Tests loaded',
  PARTICIPATED_IN_ABTEST_ANALYTICS = '[Analytics] [UI] User participated in AB Test',
  SET_ACHIEVEMENT_UNLOCKED = '[UI] Achievement unlocked',
  SET_NEXT_ACTION = '[UI] Set next action',
  SHOW_NEXT_ACTION = '[UI] Show next action',
  HIDE_NEXT_ACTION = '[UI] Hide next action',
  COMPLETE_NEXT_ACTION = '[UI] Complete next action',
  HIDE_ACHIEVEMENT = '[UI] Hide Achievement',
  FETCH_BADGES_SUCCESS = '[UI] Fetch badges sucess',
  TRIGGERED_ACHIEVEMENT_BADGE_POPUP = '[UI] [Analytics] Triggered achievement badge popup',
  CLICKED_SEE_ALL_ACHIEVEMENTS = '[UI] [Analytics] Clicked to see all planning achievements',
  INTERACTED_WITH_ACHIEVEMENT_BADGE_POPUP = '[UI] [Analytics] Interacted with achievement badge popup',
  SHOW_MOBILE_NAV = '[UI] Show mobile nav',
  TEST = '[UI] Test',
  IDENTIFY_VENUE_RECOMMENDED_SUPPLIERS = '[UI] Identify venue recommended suppliers',
  TOGGLE_NOT_INTERESTED_IN_SUPPLIER_MODAL = '[UI] Toggle lost lead modal',
  TOGGLE_SUPPLIER_INTEREST_CONFIRMATION_MODAL = '[UI] Toggle supplier interest confirmation modal',
  CLICKED = '[UI] Clicked',
  USED_QUICK_NAVIGATION_ON_HOMEPAGE = '[UI] Used quick navigation on homepage',
  TOGGLE_RATING_MODAL = '[UI] Toggle rating modal',
}

export type IToggleFullscreenImageModalAction = ReturnType<typeof toggleFullscreenImageModal>;
export type ISettingsToggleLinkEmailFormAction = ReturnType<typeof settingsToggleLinkEmailForm>;
export type ISettingsToggleChangeEmailFormAction = ReturnType<typeof settingsToggleChangeEmailForm>;
export type ITogglePushNotificationOptInAction = ReturnType<typeof togglePushNotificationOptIn>;
export type ITogglePushNotificationOptInAnalyticsAction = ReturnType<
  typeof togglePushNotificationOptInAnalytics
>;
export type IToggleNavMenuDrawerAction = ReturnType<typeof toggleNavMenuDrawer>;
export type ISetABTestVariantAction = ReturnType<typeof setABTestVariant>;
export type ISetABTestLoadedAction = ReturnType<typeof setABTestsLoaded>;
export type IShowMobileNavAction = ReturnType<typeof showMobileNav>;
export type IParticipatedInABTestAnalyticsAction = ReturnType<typeof participatedInABTestAnalytics>;
export type IToggleSpecialOfferPopupAction = ReturnType<typeof toggleSpecialOfferPopup>;
export type IFetchBadgesSuccessAction = ReturnType<typeof fetchBadgesSuccess>;
export type IToggleLostLeadModal = ReturnType<typeof toggleLostLeadModal>;
export type IUsedQuickNavigationOnHomePage = ReturnType<typeof usedQuickNavigationOnHomepage>;
