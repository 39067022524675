import { getIsTestActivated, getTestVariant } from 'lib/ab-tests/selectors';
import { ABTestData } from 'lib/ab-tests/types';
import { IApplicationState } from 'lib/types';
import { useABTestIntegration } from '../hooks/use-ab-test-integration';

export const globalSearchEnabledTestData: ABTestData = {
  id: 'LIVE-19547_EnableGlobalSearch',
  name: 'LIVE-19547 EnableGlobalSearch',
  description: 'Enable global search',
};

export const getIsGlobalSearchEnabled = (state: IApplicationState) =>
  getTestVariant(state, globalSearchEnabledTestData.id) !== 'control';

export const getIsGlobalSearchTestFullyInitialized = (state: IApplicationState) =>
  getIsTestActivated(state, globalSearchEnabledTestData.id);

export const useABTest_EnableGlobalSearch = () => {
  const triggerCondition = true;

  useABTestIntegration(globalSearchEnabledTestData.id, triggerCondition);
};
