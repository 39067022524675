import { createSelector } from 'reselect';
import { TDeviceType } from '@bridebook/ui';
import { selectAuthStatus } from 'lib/auth/selectors';
import { IApplicationState } from 'lib/types';

const getAppState = ({ app }: IApplicationState) => app;
const getAppLocale = ({ app }: IApplicationState) => app.locale;
const getNextUrl = ({ app }: IApplicationState) => app.nextUrl;
export const getDevice = (state: IApplicationState) => getAppState(state).device;

/* ############################################################################
 *  MEMOIZED SELECTORS
 * ######################################################################### */

export const getIsMobileSafari = createSelector(
  getDevice,
  ({ isMobileSafari }) => !!isMobileSafari,
);

/**
 * @deprecated
 * use selectDeviceType instead
 */
export const getIsMobile = createSelector(getDevice, ({ isMobile }) => !!isMobile);
/**
 * @deprecated
 * use selectDeviceType instead
 */
export const getIsTablet = createSelector(getDevice, ({ isTablet }) => !!isTablet);
export const getIsLandscape = createSelector(getDevice, ({ isLandscape }) => isLandscape);
export const getIsOnline = createSelector(getAppState, ({ online }) => online);
export const selectIsCordova = (state: IApplicationState) => state.app.device.isCordova;
/**
 * @deprecated
 * use selectDeviceType instead
 */
export const getIsDesktop = createSelector(getDevice, ({ isDesktop }) => !!isDesktop);
export const selectDeviceType: (state: IApplicationState) => TDeviceType = createSelector(
  getDevice,
  ({ isTablet, isDesktop, isMobile }) => {
    if (isDesktop) {
      return 'desktop';
    } else if (isMobile) return 'mobile';
    else if (isTablet) {
      return 'tablet';
    } else {
      return null;
    }
  },
);
export const getIsDesktopDesign = (state: IApplicationState) => state.app.device.isDesktopDesign;
export const getIsIOS = createSelector(getDevice, ({ isiOS }) => isiOS);
export const getIsAndroid = createSelector(getDevice, ({ isAndroid }) => isAndroid);
export const selectLayoutProps = createSelector(
  [getIsMobile, getIsTablet, selectAuthStatus, selectIsCordova, getIsDesktopDesign, getIsDesktop],
  (isMobile, isTablet, isLoggedIn, isCordova, isDesktopDesign, isDesktop) => ({
    isMobile,
    isTablet,
    isLoggedIn,
    isCordova,
    isDesktopDesign,
    isDesktop,
  }),
);

//@deprecated use getLastPath instead as you can't rely on what is returned by this selector
export const getPreviousPath = createSelector(getAppState, ({ previousPath }) => previousPath);
export const getLastPath = createSelector(getAppState, ({ lastPath }) => lastPath);

export const getPathname = createSelector(getAppState, ({ pathname }) => pathname);
export const getServerPage = createSelector(getAppState, ({ serverPage }) => serverPage);
export const getQuery = createSelector(getAppState, ({ query }) => query);
export const getPreviousSearch = createSelector(
  getAppState,
  ({ previousSearch }) => previousSearch,
);
export const getPreviousSearchQuery = createSelector(
  getAppState,
  ({ previousSearchQuery }) => previousSearchQuery,
);

export const getDeviceWidth = createSelector([getDevice], ({ width }) => width);

// This is necessary when comparing URLs because in Android the `nextUrl` will
// not have the locale
export const getNormalizedNextUrl = createSelector(
  [getAppLocale, getNextUrl],
  (locale, nextUrl) => nextUrl?.replace(new RegExp(`^/${locale}`), '') || '',
);

export const getHost = createSelector(getAppState, ({ device }) => device.host);

export const getShortlistTabFromQuery = createSelector(getAppState, ({ query }) => query.category);

export const getIsMobileKeyboardOpened = createSelector(
  getAppState,
  ({ mobileKeyboardOpened }) => mobileKeyboardOpened,
);
