import { useEffect, useRef, useState } from 'react';
import Experiments, { CohortNames } from '@bridebook/toolbox/src/ab-testing/ab-testing';
import { getIsE2E } from '@bridebook/toolbox/src/e2e/get-is-e2e';
import { basicUserIdCookie } from 'app-shared/lib/ab-tests/basic-user-id-cookie';
import { useABTestsLoggedIn } from 'lib/ab-tests/hooks/use-ab-tests-logged-in';
import { getIsTestActivated } from 'lib/ab-tests/selectors';
import { participatedInABTestAnalytics, setABTestVariant } from 'lib/ui/actions';
import { loggedOutOnlyPages } from 'lib/url-helper';
import { getCurrentUserId } from 'lib/users/selectors';
import { useDispatch, useSelector } from 'lib/utils';
import { usePathWithoutMarket } from 'lib/utils/url';

const SALT = '3b3652a7ca980aa1f7c9a31b44b230f7';

/**
 * Activates an AB Test
 *
 * @param testId - id of the test from firestore
 * @param triggerCondition (optional) - test will run only if this is undefined or true
 */
export const useABTestIntegration = (testId: string, triggerCondition?: boolean) => {
  const dispatch = useDispatch();
  const loggedInUserId = useSelector(getCurrentUserId);
  const path = usePathWithoutMarket();

  const [userId, setUserId] = useState(loggedInUserId);

  const isOnLoggedOutPage = loggedOutOnlyPages.some((page) => path.includes(page));

  // The first client-side render has to match the server, so we can set a user id
  // only in the second render using `useEffect`
  useEffect(() => {
    if (userId || !isOnLoggedOutPage) return;
    setUserId(basicUserIdCookie.set());
  }, [isOnLoggedOutPage, userId]);

  const isTestActivated = useSelector((state) => getIsTestActivated(state, testId));
  const { abTests } = useABTestsLoggedIn();
  const abTest = abTests?.find((test) => test.id === testId);
  const assignedVariant = useRef<CohortNames | undefined>();

  useEffect(() => {
    if (!abTest) return;
    // Return if running in test mode
    if (getIsE2E()) return;
    // Return if the test has been activated before.
    if (isTestActivated) return;
    // Return if trigger conditions are not met
    if (triggerCondition === false) return;
    if (!userId) return;

    const experiments = new Experiments(
      {
        version: '1.0',
        experiments: [abTest],
        salt: SALT,
      },
      userId,
      { id: userId },
    );
    assignedVariant.current = experiments.getCohort(testId) as CohortNames;

    // Activate the test
    dispatch(setABTestVariant({ id: testId, variant: assignedVariant.current }));

    // Send analytics to mixpanel
    dispatch(participatedInABTestAnalytics({ abTest, variant: assignedVariant.current }));
  }, [abTest, dispatch, isTestActivated, testId, triggerCondition, userId]);
  return {
    variant: assignedVariant.current,
  };
};
