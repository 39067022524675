import { isNil, reject } from 'ramda';
import { getVenueMatchesRange, mapSlugToFriendlyUrlSlug } from '@bridebook/toolbox/src';
import { Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { getPageSize } from '@bridebook/toolbox/src/search-suppliers/get-page-size';
import { SearchType } from '@bridebook/toolbox/src/search-suppliers/types';
import { getAreaRegex } from '@bridebook/toolbox/src/search/area-regex';
import { IFetchSearchPromiseArgs } from 'lib/search/types';
import getTargeting from './get-targeting';

/**
 * createSearchQuery - prepare search request query
 * @param slug (required)
 * @param area
 * @param oldSlug
 * @param sort
 * @param guests - used for budget match / capacityDining
 * @param profile
 * @param isLoggedIn
 * @param locationData - static Firebase location props
 * @param countryCode - provided in case of logged-out user and no locationData https://bridebook.atlassian.net/browse/LIVE-14278
 * @param searchOnMove - if true ignore predefined region "bounds"
 * @param size
 * @param isConcierge
 * @param query
 */
const createSearchQuery = ({
  slug,
  area,
  oldSlug = false,
  sort,
  guests,
  profile = null,
  isLoggedIn,
  locationData = null,
  countryCode,
  searchOnMove,
  size,
  isConcierge,
  ...query
}: IFetchSearchPromiseArgs) => {
  const loggedIn = typeof isLoggedIn !== 'undefined' ? isLoggedIn : !!profile?.id;

  // Bounds should be used for all suppliers and venues
  const bounds = searchOnMove
    ? {}
    : loggedIn
    ? locationData?.bounds?.loggedIn
    : locationData?.bounds?.loggedOut || {};

  const searchInCounties = locationData?.searchInCounties || [];

  // Check for i18n url format (area--adminArea1--countryCode)
  const isParametrisedURL = area.includes('--');
  // Remove non-letters and see if there are any actual letters left
  const containsLetters = area && area.replace(getAreaRegex(false), '').length > 0;
  const finalCountryCode = profile?.l10n?.country || locationData?.countryCode || countryCode;
  const finalArea = containsLetters
    ? // Replace non-letters with spaces
      area.replace(getAreaRegex(false), ' ')
    : Gazetteer.getCountryName(finalCountryCode);

  const searchType: SearchType | string = searchOnMove
    ? SearchType.Map
    : locationData?.type || SearchType.Town;

  let q: Omit<IFetchSearchPromiseArgs, 'slug'> = {
    type: oldSlug ? slug : mapSlugToFriendlyUrlSlug(slug as NonNullable<typeof slug>).oldSlug,
    area: isParametrisedURL ? area : finalArea,
    ...bounds,
    ...query,
    searchType,
    country:
      locationData?.country ||
      profile?.location?.adminArea?.[1] ||
      profile?.location?.country ||
      '',
    county: locationData?.county || null,
    countryCode: finalCountryCode,
    sort,
    isLoggedIn: loggedIn,
    // isMockLoggedIn: searchType === 'county', // For county type searches always mock logged in request LIVE-15242
    locationInfo: Boolean(Object.keys(locationData || {}).length),
    searchInCounties,
    region: locationData?.region || null,
    size: size || getPageSize({ isLoggedIn: loggedIn }),
    isConcierge,
  };

  if (guests) query.capacityDining = getVenueMatchesRange(guests);
  if (profile?.id) q = getTargeting({ q, profile });
  return reject(isNil, q);
};

export default createSearchQuery;
