import { AbstractCollection } from '../../abstract/Collection';
import { AbstractQuery } from '../../abstract/Collection/Query';
import { AbstractDocument, Identifiable, Timestampable, Trackable } from '../../abstract/Document';
import { type User, Users } from '../Users';
import type { ITask } from './Tasks.types';
import { type DocumentChangeType, orderBy, type Query, query, type QueryConstraint, where } from 'firebase/firestore';

@Identifiable
@Timestampable
@Trackable
export class Task extends AbstractDocument<ITask> {
  readonly collections = {};
}

export class Tasks extends AbstractCollection<Task, ITask> {
  static definitions = {
    _: {} as ITask,
  };

  static path = 'tasks';

  public static todayTaskGroupId = 'efa48781-7fd4-46ef-8256-dc4e02f0ea14';

  constructor(document: User) {
    super(document.collection(Tasks.path), Task);
  }

  get user() {
    return Users._.getById(this.reference.parent.id);
  }

  get next() {
    return this.query([where('done', '==', false), orderBy('order', 'asc')]);
  }

  queryTodayTasks<R = ITask>(on?: DocumentChangeType[]) {
    const criteria: QueryConstraint[] = [where('group', '==', Tasks.todayTaskGroupId), orderBy('__name__')];

    return new AbstractQuery<R>(query(this.reference, ...criteria) as Query<R>, on);
  }
}
